<template>
  <div v-if="content.content || resolvedLink" class="overlay-content">
    <div v-if="content.content" class="overlay-content__scrim"></div>
    <a
      v-if="resolvedLink"
      class="overlay-content__link"
      v-bind="resolvedLink"
    ></a>
    <div v-if="content.content" class="overlay-content__wrap">
      <div
        class="overlay-content__wrap-content"
        :class="[`overlay-content__wrap-content--${content.align || 'fill'}`]"
        :style="{ ...content.style, padding: padPx }"
      >
        <!-- <logo color="white" lucid /> -->
        <block-component :content="content.content" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { isBoolean } from "lodash";
import { defineComponent, type PropType } from "vue";
import type { ResolvedLink } from "~~/utils/links";
import type { OverlayContent } from ".";

export default defineComponent({
  name: "OverlayBlock",
  props: {
    content: {
      type: Object as PropType<OverlayContent>,
      required: true,
    },
  },
  computed: {
    resolvedLink(): ResolvedLink | undefined {
      return resolveLink(this.content.link);
    },
    padPx() {
      if (!isBoolean(this.content.pad)) {
        return convertToUnit(this.content.pad);
      }
      return this.content.pad ? "15px" : 0;
    },
  },
});
</script>

<style lang="scss" scoped>
.overlay-content {
  border-radius: inherit;
  display: flex;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  &__scrim {
    pointer-events: auto;
    background: rgb(var(--v-theme-on-surface));
    border-radius: inherit;
    bottom: 0;
    left: 0;
    opacity: 32%;
    position: absolute;
    right: 0;
    top: 0;
  }
  &__link {
    border-radius: inherit;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: auto;
    z-index: 1;
  }
  // &__content {
  //   outline: none;
  //   position: absolute;
  //   pointer-events: auto;
  //   contain: layout;
  // }
  &__wrap {
    outline: none;
    position: absolute;
    pointer-events: none;
    contain: layout;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  &__wrap-content {
    height: auto;
    z-index: 2;
    pointer-events: auto;
    color: white;
    &--fill {
      position: relative;
      height: 100%;
      width: 100%;
    }
    &--top {
      position: absolute;
      top: 0;
    }
    &--bottom {
      position: absolute;
      bottom: 0;
    }
  }
}
</style>
